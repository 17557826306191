import { render, staticRenderFns } from "./AppModal.vue?vue&type=template&id=24515ec8&scoped=true&"
import script from "./AppModal.vue?vue&type=script&lang=js&"
export * from "./AppModal.vue?vue&type=script&lang=js&"
import style0 from "./AppModal.vue?vue&type=style&index=0&id=24515ec8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../.yarn/__virtual__/vue-loader-virtual-ca742ce17d/0/cache/vue-loader-npm-15.9.6-b3270576fe-38ad9b5d56.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24515ec8",
  null
  
)

export default component.exports