import { render, staticRenderFns } from "./PluginlessWrapper.vue?vue&type=template&id=3c456f6e&scoped=true&"
import script from "./PluginlessWrapper.vue?vue&type=script&lang=js&"
export * from "./PluginlessWrapper.vue?vue&type=script&lang=js&"
import style0 from "./PluginlessWrapper.vue?vue&type=style&index=0&id=3c456f6e&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../.yarn/__virtual__/vue-loader-virtual-ca742ce17d/0/cache/vue-loader-npm-15.9.6-b3270576fe-38ad9b5d56.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c456f6e",
  null
  
)

export default component.exports