import { RtspProtocol } from '@vivotek/lib-medama';
import RTCPeerClient from './rtc_peer_client';
import RTCHttpProtocol from './rtc_http_protocol';
import RTCVolalarmProtocol from './rtc_volalarm_protocol';

import detectedBrowser from '@vivotek/lib-utility/detectedBrowser';
import MicroEvent from '@vivotek/lib-utility/microevent';

function RTCClient(options) {
  const self = this;

  this.deviceId = options.deviceId || '';
  this.authToken = options.authToken || '';
  this.webrtcVer = options.webrtcVer || 'v1';
  this.ws = null;
  this.pClient = null;
  this.rtcServVer = 1;
  this.dataChannels = {};
  this.connected = false;
  this.onlineRegion = options.onlineRegion;

  let callerId;
  let iceConfig;

  const output = options.output || function output() {
    // console.log.apply(console, arguments);
  };

  this.connect = (callback) => {
    const { onlineRegion } = self;
    const regionPrefix = (!onlineRegion) ? '' : (`${onlineRegion}.`);
    const signalingSource = (regionPrefix + window.CONFIG_SINGSVR_ADDRESS) || 'localhost';
    let url = `${signalingSource}/require.wss`;
    if (signalingSource.indexOf('://') < 0) {
      url = `wss://${url}`;
    }

    output(`connect to ${url}`);

    this.ws = new WebSocket(url);

    this.ws.onerror = function onError() {
      this.trigger('abort', new Error('fail to connect to signal'));
    }.bind(this);

    const { webrtcVer, authToken, deviceId } = this;

    this.ws.onopen = function onOpen() {
      console.log('WebSocket opened');

      const loginMsg = JSON.stringify({
        request: 'webrtc login',
        agent_ver: navigator.userAgent,
        webrtc_ver: webrtcVer,
        auth_token: authToken,
        auth_deviceid: this.deviceId
      });

      output('WebSocket send:', loginMsg);
      this.ws.send(loginMsg);

      this.ws.onmessage = function onMessage(evt) {
        output('WebSocket onmessage:', evt.data);

        const rWebrtcVer = /v(\d+)\.*.*/;
        const data = JSON.parse(evt.data);
        const disconnect = (reason) => {
          if (reason) { this.reason = reason; }
          if (this.pClient) { this.pClient.allowSendIce(false); }
          this.ws.close();
        };

        const rTurnsIPAddress = /^turns:(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])/;
        // command response
        if (data.response) {
          if (data.request === 'webrtc login' && data.response === 'success') {
            callerId = data.caller_id;
            iceConfig = data.ice_config;

            // prevent warning "TURNS is not yet supported" on firefox 53 and safari
            if ((detectedBrowser.isFirefox || detectedBrowser.isSafari)
                && iceConfig.iceServers) {
              iceConfig.iceServers = iceConfig.iceServers.filter((server) => !rTurnsIPAddress.test(server.urls));
            }

            const msg = {
              request: 'webrtc message',
              device_id: deviceId,
              caller_id: callerId
            };

            this.pClient = new RTCPeerClient({
              iceServers: iceConfig.iceServers
            });

            this.pClient.on('offer', (desc) => {
              const sdp = {
                type: desc.type,
                sdp: desc.sdp
              };
              const offerMsg = JSON.stringify({
                webrtc_ver: webrtcVer,
                agent_ver: navigator.userAgent,
                auth_token: authToken,
                ice_config: iceConfig,
                type: 'offer',
                sdp,
                ...msg
              });

              output('WebSocket send:', offerMsg);
              this.ws.send(offerMsg);
            });

            this.pClient.on('icecandidate', (candidate) => {
              const iceMsg = JSON.stringify({
                type: 'ice',
                sdp: candidate,
                ...msg
              });

              output('WebSocket send:', iceMsg);
              this.ws.send(iceMsg);
            });

            this.pClient.on('connected', (dataChannels) => {
              output('WebRTC connected');

              this.dataChannels = dataChannels;
              this.connected = true;

              this.ws.close();

              this.pClient.getConnectionType().then((type) => {
                this.type = type;
              }).finally(() => {
                const additionalChannels = [
                  'liveview_0',
                  'liveview_1',
                  'liveview_2',
                  'liveview_3',
                  'playback',
                  'snapshot'
                ];
                // create 'liveview' * 4, 'playback' & 'snapshot' datachannels
                // for rtc_client v2.0
                if (this.rtcServVer > 1) {
                  additionalChannels.forEach((label) => {
                    this.pClient.createDataChannel(label)
                      .then(() => {
                        const index = additionalChannels.indexOf(label);
                        if (index < 0) { return; }

                        additionalChannels.splice(index, 1);
                        // check each channel for label, then trigger connected event
                        if (additionalChannels.length <= 0) { this.trigger('connected'); }
                      });
                  });
                } else {
                  this.trigger('connected');
                }
              });
            });

            this.pClient.on('close', (res) => {
              output('WebRTC closed');

              if (res) { this.reason = res; }

              this.close();
            });
          } else if (data.response === 'device offline'
                   || data.response === 'permission denied'
                   || (data.type === 'offer' && data.response !== 'success')
          ) {
            if (data.online_region !== onlineRegion) {
              this.onlineRegion = data.online_region;
              disconnect('online_region changed');
            } else {
              disconnect(data.response);
            }
          } else if (this.pClient) {
            this.pClient.allowSendIce();
          } else {
            disconnect(data.response);
          }
        } else if (data.type === 'answer') { // webrtc info
          this.pClient.setRemoteDescription(data.sdp);

          if (data.webrtc_ver && rWebrtcVer.test(data.webrtc_ver)) {
            this.rtcServVer = Number(data.webrtc_ver.match(rWebrtcVer)[1]);
          }
        } else if (data.type === 'ice') {
          this.pClient.addIceCandidate(data.sdp);
        } else if (data.type === 'reject') {
          output('WebRTC reject');

          disconnect('reject by signal');
        }
      }.bind(this);

      this.ws.onclose = function onClose() {
        output('WebSocket closed');

        if (this.pClient && this.pClient.connected) { return; }
        if (this.reason === 'online_region changed') {
          if (onlineRegion !== this.onlineRegion) {
            this.connect();
          }
        } else {
          this.trigger('abort', new Error(this.reason));
        }
      }.bind(this);
    }.bind(this);
  };

  this.close = function close() {
    const { dataChannels } = this;
    // close existed dataChannel
    Object.keys(dataChannels).forEach((dataChannel) => {
      dataChannels[dataChannel].close();
    });

    this.dataChannels = {};
    this.connected = false;

    if (this.pClient) { this.pClient.close(); }

    if (this.ws?.readyState !== 3) { this.ws.close(); }

    this.trigger('close');
  }.bind(this);

  this.createDataChannel = function createDataChannel(label, channelOptions) {
    if (label === undefined || this.dataChannels[label]) { return Promise.reject(); }
    return new Promise((resolve) => {
      this.pClient.createDataChannel(label, channelOptions)
        .then((channel) => {
          resolve(channel);
        });
    });
  };
}

RTCClient.prototype.getDataChannel = function getDataChannel(label) {
  return this.dataChannels[label];
};

RTCClient.prototype.getHttpChannel = function getHttpChannel() {
  if (!this.httpChannel) {
    this.httpChannel = new RTCHttpProtocol(this.getDataChannel('data'));
  }

  return this.httpChannel;
};

RTCClient.prototype.getLiveviewChannel = function getLiveviewChannel() {
  if (!this.liveviewChannelInUse) {
    this.liveviewChannelInUse = [];
  }

  if (!this.liveviewChannelPool) {
    this.liveviewChannelPool = [];
  }

  if (!this.liveviewChannelPool.length) {
    for (let i = 0, channel; i < 4; i += 1) {
      channel = this.getDataChannel(`liveview_${i}`);

      this.liveviewChannelPool.push(new RtspProtocol(channel));
    }
  }

  const liveviewChannel = this.liveviewChannelPool.shift();

  if (liveviewChannel) { this.liveviewChannelInUse.push(liveviewChannel); }

  return liveviewChannel;
};

RTCClient.prototype.resetLiveviewChannel = function resetLiveviewChannel(liveviewChannel) {
  if (!this.liveviewChannelInUse) {
    this.liveviewChannelInUse = [];
  }

  const index = this.liveviewChannelInUse.indexOf(liveviewChannel);
  if (index < 0) { return; }

  this.liveviewChannelInUse.splice(index, 1);
  this.liveviewChannelPool.push(liveviewChannel);
};

RTCClient.prototype.getPlaybackChannel = function getPlaybackChannel() {
  if (!this.playbackChannel) {
    this.playbackChannel = new RtspProtocol(this.getDataChannel('playback'));
  }

  return this.playbackChannel;
};

RTCClient.prototype.getSnapshotChannel = function getSnapshotChannel() {
  if (!this.snapshotChannel) {
    this.snapshotChannel = new RtspProtocol(this.getDataChannel('snapshot'));
  }

  return this.snapshotChannel;
};

RTCClient.prototype.getCacheChannel = function getCacheChannel() {
  if (!this.cacheChannel) {
    this.cacheChannel = new RtspProtocol(this.getDataChannel('cache'));
  }

  return this.cacheChannel;
};

RTCClient.prototype.getVolalarmChannel = function getVolalarmChannel() {
  if (!this.volalarmChannel) {
    this.volalarmChannel = new RTCVolalarmProtocol(this.getDataChannel('volalarm'));
  }

  return this.volalarmChannel;
};

export default MicroEvent.mixin(RTCClient);
