import SvgIcon from './SvgIcon.vue';
import('./svg/icon_status_camera_default.svg');

const install = function(Vue, options) {
  Vue.component(options && options.tagName ? options.tagName : 'SvgIcon', SvgIcon);
  function importAll(r) {
    r.keys().forEach(r);
  }
  const req = require.context('./svg', false, /\.svg$/);
  importAll(req);
};

export default { install };
